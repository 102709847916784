import React from 'react';

import Title from './Title';

import Paragraph from './Paragraph';
import Image from './Image';
import HighlightedText from './HighlightedText';
import BlogMeme from './BlogMeme';
import Subtitle from './Subtitle';
import BlogLink from './BlogLink';

export const MDXLayoutComponents = {
  h2: (props) => <Title {...props} />,
  h3: (props) => <Subtitle {...props} />,
  p: (props) => <Paragraph style={{color: 'red'}} {...props} />,
  img: (props) => <Image {...props} />,
  Mark:  (props) => <HighlightedText {...props} />,
  Meme: (props) => <BlogMeme {...props} />,
  ul: (props) => <ul className='blog-ul' {...props}/>,
  ol: (props) => <ol className='blog-ol' {...props} />,
  a: (props) => <BlogLink {...props} />
};

// export const MDXGlobalComponents = {
//   Link,
//   YouTube,
// };
