import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';

import { MDXLayoutComponents } from '../Elements';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import BlogHeroLayout from '../../Layouts/BlogHeroLayout';
import { Container } from 'react-bootstrap';
import Scrollbars from 'react-custom-scrollbars';
import Footer from '../../Footer/Footer';
import Fade from 'react-reveal/Fade';
import BlogControls from '../BlogControls';
import BlogBanner from '../../BlogBanner';

const BlogPostTemplate = (props) => {
  const { description: siteDescription } = props.data.site.siteMetadata;

  const { title, description: frontmatterDescription } = props.data.mdx.frontmatter;

  const description = frontmatterDescription || siteDescription;
 

  return (
    <BlogHeroLayout metaTitle={title}>
      <Scrollbars style={{ height: '100vh', width: '100%' }}>
        <Container className="px-md-0 fixed-padding">
          <BlogBanner bannerData={props.data.mdx.frontmatter} />
        </Container>
        <Container className="px-md-0 container text-left fixed-padding" style={{ marginTop: '60px' }}>
          <MDXProvider components={{ ...MDXLayoutComponents }}>
            <Fade bottom distance="100px" delay={1000} duration={600}>
              <MDXRenderer >{props.data.mdx.body}</MDXRenderer>
            </Fade>
          </MDXProvider>
        </Container>

        <Container className="px-md-0 py-4 fixed-padding">
          <hr style={{ background: '#ffffff80' }} />
        </Container>
        <Container className="px-md-0 fixed-padding">
          <BlogControls data={props.pageContext} />
        </Container>
        <Footer />
      </Scrollbars>
    </BlogHeroLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostsBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        timeToRead
        bannerImg {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
