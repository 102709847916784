import React from 'react';

function Subtitle(props) {
  return (
    <h2 style={{ color: 'white',  fontSize: '2rem', lineHeight: '3rem', marginBottom: '20px' }}>
      {props.children}
    </h2>
  );
}

export default Subtitle;