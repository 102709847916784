import React from 'react';

function Paragraph(props) {
  return (
    <p
      style={{
        color: '#fafafa',
        marginBottom: '30px',
        fontSize: '1.5rem',
        lineHeight: '3rem',
        textAlign: 'justify',
        textJustify: 'inter-word',
      }}
    >
      {props.children}
    </p>
  );
}

export default Paragraph;
