import React from 'react';

function HighlightedText(props) {
  return (
    <mark
      style={{
          borderRadius: '3px',
          backgroundColor: '#ededed'
      }}
    >
      {props.children}
    </mark>
  );
}

export default HighlightedText;
