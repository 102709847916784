import React from 'react';
import ReactPlayer from 'react-player';
import Run from '../../../images/hunter.webp';
import ThumbsUpMeme from '../../../images/memes/thumbsup.webp';
import CleanupMeme from '../../../images/memes/cleanup.webp';
import CapMeme from '../../../images/memes/dontThink.webp';
import TestingMeme from '../../../images/memes/testing.webp';
import SmallMeme from '../../../images/memes/small.webp';

function BlogMeme(props) {
  let memeInstance = ThumbsUpMeme;
  switch (props.meme) {
    case 'Hunter':
      memeInstance = Run;
      break;
    case 'Cleanup':
      memeInstance = CleanupMeme;
      break;
    case 'DontThink':
      memeInstance = CapMeme;
      break;
    case 'Testing':
      memeInstance = TestingMeme;
      break;
    case 'Small':
      memeInstance = SmallMeme;
      break;
    default:
      memeInstance = ThumbsUpMeme;
      break;
  }
  return (
    <div className="blog-meme-wrapper">
      <ReactPlayer
        className="blog-meme-player"
        width={'100%'}
        url={Run}
        light={props.meme ? memeInstance : ThumbsUpMeme}
        playIcon={() => null}
      />
    </div>
  );
}

export default BlogMeme;
