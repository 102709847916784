import React from 'react';

function Title(props) {
  return (
    <h3 style={{ color: 'white', fontWeight: 'bold', fontSize: '2.4rem', lineHeight: '4.5rem', marginBottom: '20px' }}>
      {props.children}
    </h3>
  );
}

export default Title;
