import React, { useEffect, useRef, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Col, Image, Row } from 'react-bootstrap';
import { heartIcon, copyToClipboardIcon, shareIcon } from '../SvgComponents/socialIcons';
import Splitting from 'splitting';
import NeuRoundButton from '../Buttons/NeuRoundButton';

const BlogBanner = (props) => {
  const headingRef = useRef(null);

  useEffect(() => {
    // Animation for fading out
    Splitting({
      /* target: String selector, Element, Array of Elements, or NodeList */
      target: '[data-splitting]',
      /* by: String of the plugin name */
      by: 'chars',
      /* key: Optional String to prefix the CSS variables */
      key: null,
    });
  }, []);

  return (
    <div className="d-flex blog-banner">
      <div className="blog-banner-left">
        <div
          className="surface w-100 h-100"
          style={{
            position: 'relative',
            width: '100%',
            backgroundImage: `url(${props.bannerData?.bannerImg?.childImageSharp.fluid.src})`,
          }}
        >
          {/* <Image src={props.bannerData?.bannerImg?.childImageSharp.fluid.src} /> */}
        </div>
      </div>

      <div className="blog-banner-right">
        <h1 className="blog-title" data-splitting>
          {props.bannerData.title}
        </h1>
        <div className="d-flex align-items-center mt-5">
          <Fade bottom delay={600} duration={300}>
            <div className="auth mr-5">
              <span className="mr-3">
                <svg
                style={{marginBottom: '4px'}}
                  id="user"
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="18"
                  viewBox="0 0 12 18"
                >
                  <path
                    id="Path_1384"
                    data-name="Path 1384"
                    d="M16,7a4,4,0,1,1-4-4A4,4,0,0,1,16,7ZM14,7a2,2,0,1,1-2-2A2,2,0,0,1,14,7Z"
                    transform="translate(-6 -3)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_1385"
                    data-name="Path 1385"
                    d="M16,15a1,1,0,0,0-1-1H9a1,1,0,0,0-1,1v6H6V15a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v6H16Z"
                    transform="translate(-6 -3)"
                    fill="#fff"
                  />
                </svg>
              </span>
              By Zabi
            </div>
          </Fade>
          <Fade bottom delay={800} duration={300}>
            <div className="read">
              <span className="mr-3">
                <svg
                style={{marginBottom: '4px'}}
                  id="alarm"
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="18"
                  viewBox="0 0 22 19"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M5.459,2,1,6.015,2.338,7.5,6.8,3.486Z"
                    transform="translate(-1 -2)"
                    fill="#fff"
                  />
                  <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M11,8h2v4h3v2H11Z"
                    transform="translate(-1 -2)"
                    fill="#fff"
                  />
                  <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M3,12a9,9,0,1,1,9,9A9,9,0,0,1,3,12Zm2,0a7,7,0,1,1,7,7A7,7,0,0,1,5,12Z"
                    transform="translate(-1 -2)"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M18.541,2,23,6.015,21.662,7.5,17.2,3.486Z"
                    transform="translate(-1 -2)"
                    fill="#fff"
                  />
                </svg>
              </span>
              {props.bannerData.timeToRead} min read
            </div>
          </Fade>
        </div>
        {/* <div className="d-flex align-items-center mt-5">
          <Row>
            <Col>
              <Fade bottom delay={1000} duration={300}>
                <NeuRoundButton svgicon={heartIcon} />
              </Fade>
            </Col>
            <Col>
              <Fade bottom delay={1200} duration={300}>
                <NeuRoundButton svgicon={copyToClipboardIcon} />
              </Fade>
            </Col>
            <Col>
              <Fade bottom delay={1400} duration={300}>
                <NeuRoundButton svgicon={shareIcon} />
              </Fade>
            </Col>
          </Row>
        </div> */}
      </div>
    </div>
  );
};

export default BlogBanner;
