import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const BlogControls = (props) => (
  <div className="blog-prev-next d-flex text-danger justify-content-between">
    <div className={props.data.previous === null ? 'disabled prev-btn' : 'prev-btn'}>
      <Link to={`/blog${props.data.previous?.fields.slug}`}>
        <span className="pb-4">
          <svg
            id="arrow-left"
            xmlns="http://www.w3.org/2000/svg"
            width="16.657"
            height="11.314"
            viewBox="0 0 16.657 11.314"
            fill="#fff"
          >
            <path
              id="arrow-left-2"
              data-name="arrow-left"
              d="M20.328,11v2H7.5l3.242,3.242L9.328,17.657,3.672,12,9.328,6.343l1.414,1.414L7.5,11Z"
              transform="translate(-3.672 -6.343)"
            />
          </svg>
        </span>
        Previous
        {props.data.previous !== null ? (
          <span className="subtitle">{props.data.previous.fields.title}</span>
        ) : null}
      </Link>
    </div>
    <div className={props.data.next === null ? 'next-btn disabled' : 'next-btn'}>
      <Link to={`/blog${props.data.next?.fields.slug}`}>
        <span className="pb-4">
          <svg
            id="arrow-right"
            xmlns="http://www.w3.org/2000/svg"
            width="17.416"
            height="11.314"
            fill="#fff"
            viewBox="0 0 17.416 11.314"
          >
            <path
              id="arrow-right-2"
              data-name="arrow-right"
              d="M15.038,6.343,13.627,7.761l3.27,3.255-13.605.014,0,2,13.568-.014-3.215,3.23,1.418,1.411,5.644-5.67Z"
              transform="translate(-3.292 -6.343)"
            />
          </svg>
        </span>
        Next
        {props.data.next !== null ? (
          <span className="subtitle">{props.data.next.fields.title}</span>
        ) : null}
      </Link>
    </div>
  </div>
);

export default BlogControls;
